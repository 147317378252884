@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Inter, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-4 {
  top: 1rem;
}

.left-4 {
  left: 1rem;
}

.bottom-4 {
  bottom: 1rem;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-4 {
  right: 1rem;
}

.top-1\/2 {
  top: 50%;
}

.-left-2 {
  left: -.5rem;
}

.bottom-8 {
  bottom: 2rem;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-1 {
  top: .25rem;
}

.left-8 {
  left: 2rem;
}

.-right-24 {
  right: -6rem;
}

.top-10 {
  top: 2.5rem;
}

.top-\[2px\] {
  top: 2px;
}

.-top-16 {
  top: -4rem;
}

.-top-8 {
  top: -2rem;
}

.-right-6 {
  right: -1.5rem;
}

.-top-6 {
  top: -1.5rem;
}

.-bottom-1 {
  bottom: -.25rem;
}

.-right-1 {
  right: -.25rem;
}

.-top-32 {
  top: -8rem;
}

.-bottom-16 {
  bottom: -4rem;
}

.-bottom-32 {
  bottom: -8rem;
}

.-top-4 {
  top: -1rem;
}

.-top-20 {
  top: -5rem;
}

.-bottom-4 {
  bottom: -1rem;
}

.-bottom-20 {
  bottom: -5rem;
}

.-right-4 {
  right: -1rem;
}

.right-8 {
  right: 2rem;
}

.z-50 {
  z-index: 50;
}

.z-\[200\] {
  z-index: 200;
}

.z-40 {
  z-index: 40;
}

.z-20 {
  z-index: 20;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.m-2 {
  margin: .5rem;
}

.m-auto {
  margin: auto;
}

.m-5 {
  margin: 1.25rem;
}

.m-4 {
  margin: 1rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-screen {
  height: 100vh;
}

.h-\[100px\] {
  height: 100px;
}

.h-1\/2 {
  height: 50%;
}

.h-4 {
  height: 1rem;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-16 {
  height: 4rem;
}

.h-10 {
  height: 2.5rem;
}

.h-6 {
  height: 1.5rem;
}

.h-3 {
  height: .75rem;
}

.h-20 {
  height: 5rem;
}

.h-\[4\.5rem\] {
  height: 4.5rem;
}

.h-full {
  height: 100%;
}

.h-24 {
  height: 6rem;
}

.max-h-full {
  max-height: 100%;
}

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.w-4 {
  width: 1rem;
}

.w-max {
  width: max-content;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-10 {
  width: 2.5rem;
}

.w-1\/4 {
  width: 25%;
}

.w-96 {
  width: 24rem;
}

.w-11 {
  width: 2.75rem;
}

.w-52 {
  width: 13rem;
}

.w-3 {
  width: .75rem;
}

.w-64 {
  width: 16rem;
}

.w-6 {
  width: 1.5rem;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-12 {
  width: 3rem;
}

.w-20 {
  width: 5rem;
}

.w-px {
  width: 1px;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-3\/6 {
  width: 50%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-1\/2 {
  width: 50%;
}

.min-w-max {
  min-width: max-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-lg {
  max-width: 32rem;
}

.flex-none {
  flex: none;
}

.flex-grow {
  flex-grow: 1;
}

.translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.translate-x-1 {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.cursor-cell {
  cursor: cell;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.select-none {
  user-select: none;
}

.resize {
  resize: both;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse) );
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse) );
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse) );
}

.space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(8rem * var(--tw-space-y-reverse) );
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse) );
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(2rem * var(--tw-space-y-reverse) );
}

.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse) );
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-4 {
  border-width: 4px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-red-700\/70 {
  border-color: #b91c1cb3;
}

.border-blue-200\/90 {
  border-color: #bfdbfee6;
}

.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity) );
}

.border-blue-200\/20 {
  border-color: #bfdbfe33;
}

.border-blue-900\/50 {
  border-color: #1e3a8a80;
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity) );
}

.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgb(126 34 206 / var(--tw-border-opacity) );
}

.border-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgb(194 65 12 / var(--tw-border-opacity) );
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity) );
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity) );
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity) );
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.bg-transparent {
  background-color: #0000;
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity) );
}

.bg-blue-100\/50 {
  background-color: #dbeafe80;
}

.bg-blue-900\/50 {
  background-color: #1e3a8a80;
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity) );
}

.bg-blue-400\/20 {
  background-color: #60a5fa33;
}

.bg-black\/70 {
  background-color: #000000b3;
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity) );
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity) );
}

.bg-white\/50 {
  background-color: #ffffff80;
}

.bg-black\/80 {
  background-color: #000c;
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity) );
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity) );
}

.bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity) );
}

.bg-white\/40 {
  background-color: #fff6;
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity) );
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity) );
}

.bg-slate-400\/40 {
  background-color: #94a3b866;
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity) );
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity) );
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity) );
}

.bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity) );
}

.bg-blue-400\/60 {
  background-color: #60a5fa99;
}

.bg-white\/60 {
  background-color: #fff9;
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity) );
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity) );
}

.bg-cover {
  background-size: cover;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-1 {
  padding: .25rem;
}

.p-3 {
  padding: .75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-xxs {
  font-size: .6rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.font-black {
  font-weight: 900;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-thin {
  font-weight: 100;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity) );
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity) );
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-blue-100\/90 {
  color: #dbeafee6;
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity) );
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity) );
}

.text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity) );
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity) );
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity) );
}

.text-slate-800\/80 {
  color: #1e293bcc;
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity) );
}

.text-blue-900\/70 {
  color: #1e3a8ab3;
}

.text-slate-500\/90 {
  color: #64748be6;
}

.text-blue-900\/90 {
  color: #1e3a8ae6;
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity) );
}

.text-blue-900\/60 {
  color: #1e3a8a99;
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity) );
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity) );
}

.text-slate-300\/80 {
  color: #cbd5e1cc;
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity) );
}

.text-slate-400\/90 {
  color: #94a3b8e6;
}

.underline {
  text-decoration-line: underline;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-5 {
  opacity: .05;
}

.opacity-90 {
  opacity: .9;
}

.opacity-60 {
  opacity: .6;
}

.opacity-40 {
  opacity: .4;
}

.opacity-80 {
  opacity: .8;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black\/\[0\.02\] {
  --tw-shadow-color: #00000005;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/10 {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline {
  outline-style: solid;
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-sky-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity) );
}

.ring-offset-4 {
  --tw-ring-offset-width: 4px;
}

.ring-offset-white {
  --tw-ring-offset-color: #fff;
}

.blur-\[2px\] {
  --tw-blur: blur(2px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-0 {
  --tw-blur: blur(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-lg {
  --tw-blur: blur(16px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.fp-watermark {
  display: none;
}

* {
  -webkit-user-drag: none;
}

.fp-controlArrow {
  opacity: .1;
  transition: all .4s linear;
}

.fp-controlArrow:hover {
  transition: all .1s linear;
  opacity: .4 !important;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity) );
}

.hover\:text-blue-100:hover {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity) );
}

.hover\:opacity-60:hover {
  opacity: .6;
}

.hover\:mix-blend-screen:hover {
  mix-blend-mode: screen;
}

.hover\:shadow-black\/\[0\.04\]:hover {
  --tw-shadow-color: #0000000a;
  --tw-shadow: var(--tw-shadow-colored);
}

.active\:scale-95:active {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.active\:mix-blend-darken:active {
  mix-blend-mode: darken;
}

.active\:shadow-black\/\[0\.014\]:active {
  --tw-shadow-color: #00000004;
  --tw-shadow: var(--tw-shadow-colored);
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:active .group-active\:ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.peer:hover ~ .peer-hover\:opacity-100 {
  opacity: 1;
}

@media (min-width: 1024px) {
  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (min-width: 1280px) {
  .xl\:ml-4 {
    margin-left: 1rem;
  }

  .xl\:h-1\/2 {
    height: 50%;
  }

  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:w-60 {
    width: 15rem;
  }

  .xl\:w-14 {
    width: 3.5rem;
  }

  .xl\:w-12 {
    width: 3rem;
  }

  .xl\:max-w-md {
    max-width: 28rem;
  }

  .xl\:gap-12 {
    gap: 3rem;
  }

  .xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(1rem * var(--tw-space-y-reverse) );
  }

  .xl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(2rem * var(--tw-space-y-reverse) );
  }

  .xl\:p-4 {
    padding: 1rem;
  }

  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:pb-2 {
    padding-bottom: .5rem;
  }

  .xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .xl\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xl\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .xl\:leading-tight {
    line-height: 1.25;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:m-6 {
    margin: 1.5rem;
  }

  .\32 xl\:mb-8 {
    margin-bottom: 2rem;
  }

  .\32 xl\:mt-8 {
    margin-top: 2rem;
  }

  .\32 xl\:h-20 {
    height: 5rem;
  }

  .\32 xl\:w-1\/4 {
    width: 25%;
  }

  .\32 xl\:w-16 {
    width: 4rem;
  }

  .\32 xl\:w-14 {
    width: 3.5rem;
  }

  .\32 xl\:max-w-lg {
    max-width: 32rem;
  }

  .\32 xl\:gap-16 {
    gap: 4rem;
  }

  .\32 xl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(2rem * var(--tw-space-y-reverse) );
  }

  .\32 xl\:p-4 {
    padding: 1rem;
  }

  .\32 xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .\32 xl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .\32 xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .\32 xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .\32 xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .\32 xl\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .\32 xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .\32 xl\:leading-tight {
    line-height: 1.25;
  }
}

@media (min-width: 1900px) {
  .\33 xl\:m-8 {
    margin: 2rem;
  }

  .\33 xl\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .\33 xl\:mt-10 {
    margin-top: 2.5rem;
  }

  .\33 xl\:h-20 {
    height: 5rem;
  }

  .\33 xl\:h-24 {
    height: 6rem;
  }

  .\33 xl\:w-5 {
    width: 1.25rem;
  }

  .\33 xl\:w-64 {
    width: 16rem;
  }

  .\33 xl\:w-\[4\.2rem\] {
    width: 4.2rem;
  }

  .\33 xl\:w-24 {
    width: 6rem;
  }

  .\33 xl\:gap-20 {
    gap: 5rem;
  }

  .\33 xl\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse) );
  }

  .\33 xl\:p-8 {
    padding: 2rem;
  }

  .\33 xl\:p-4 {
    padding: 1rem;
  }

  .\33 xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .\33 xl\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .\33 xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .\33 xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .\33 xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .\33 xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .\33 xl\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .\33 xl\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .\33 xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .\33 xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .\33 xl\:leading-tight {
    line-height: 1.25;
  }

  .\33 xl\:leading-none {
    line-height: 1;
  }
}

@media (min-width: 2400px) {
  .\34 xl\:m-10 {
    margin: 2.5rem;
  }

  .\34 xl\:mb-12 {
    margin-bottom: 3rem;
  }

  .\34 xl\:mt-12 {
    margin-top: 3rem;
  }

  .\34 xl\:h-28 {
    height: 7rem;
  }

  .\34 xl\:w-20 {
    width: 5rem;
  }

  .\34 xl\:w-28 {
    width: 7rem;
  }

  .\34 xl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse) );
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
  }

  .\34 xl\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .\34 xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .\34 xl\:leading-none {
    line-height: 1;
  }
}

@media (min-width: 3400px) {
  .\35 xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .\35 xl\:leading-none {
    line-height: 1;
  }
}

/*# sourceMappingURL=index.826e8c07.css.map */
